import React, { useState, useEffect } from "react";
import Refresh from "../../../../assets/icons/qrefresh.svg";
import DropdownSelect from "../../../../sharedComponents/DropdownSelect/DropDdownSelect";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import styles from "./SimulationDetails.module.css";
import { allField } from "./constants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

const IndividualGraph = ({ module = "Module45", data = {}, options = [] }) => {
  const [selected, setSelected] = useState(allField);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const url = `${data?.url}#p.modelname=${module}${
    data.filtered ? `&p.location=${selected?.value}` : ""
  }`;

  useEffect(() => {
    setLoading(true);
    setKey((prev) => prev + 1);
  }, [module, url]);

  const handleRefresh = () => {
    setLoading(true);
    setKey((prev) => prev + 1);
  };

  const FilterDropdown = () => (
    <div
      style={{
        display: "flex",
        position: "absolute",
        justifyContent: "flex-end",
        zIndex: 2,
        right: "5px",
        top: "10px",
      }}
    >
      <DropdownSelect
        options={[allField, ...options]}
        value={selected.value}
        onChange={(val) => {
          setSelected(val);
          handleRefresh();
        }}
        placeholder="Select an Option"
        style={{
          width: "235px",
          height: "25px",
        }}
      />
    </div>
  );

  return (
    <div className={styles.iframeContainer}>
      {data?.filtered && !loading && <FilterDropdown />}
      <div className={styles.iframeBox}>
        <iframe
          key={key}
          width="100%"
          height="100%"
          src={url}
          onLoad={() => setLoading(false)}
        ></iframe>
      </div>
      <img
        onClick={handleRefresh}
        alt="Refresh"
        src={Refresh}
        style={{
          zIndex: 2,
          position: "absolute",
          cursor: "pointer",
          bottom: "10px",
          left: "10px",
          height: "24px",
          width: "24px",
        }}
      />
      <div
  onClick={() => setOpenDialog(true)}
  style={{
    position: "absolute",
    zIndex: 2,
    top: "10px",
    left: "10px",
    cursor: "pointer",
  }}
>
  <OpenInFullIcon style={{ fontSize: "24px" }} />
</div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="false"
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            width: "50vw",
            height: "90vh",
            maxWidth: "none",
            margin: 0,
            padding: 0,
          },
        }}
      >
        <DialogTitle
          style={{ padding: "10px", textAlign: "center" }}
        ></DialogTitle>
        <DialogContent style={{ padding: 0, overflow: "hidden" }}>
          <div className={styles.dialogIframeWrapper}>
            <div className={styles.dialogIframeScaler} style={{ position: 'relative' }}>
              {data?.filtered && !loading && <FilterDropdown />}
              <iframe
                key={`dialog-${key}`}
                src={url}
                onLoad={() => setLoading(false)}
                className={styles.dialogIframe}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IndividualGraph;