
// Evolution Graphs-
// OverAll Evolution - Psychological Well-being
export const evolutionGraph1 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/37cc6cda-5ea8-410b-b63c-ec040992c460/sheets/37cc6cda-5ea8-410b-b63c-ec040992c460_247fbd73-7045-461d-a422-389fccb49ca3/visuals/37cc6cda-5ea8-410b-b63c-ec040992c460_11145494-fda6-4549-9f36-b84b5a20c3ac?directory_alias=neom-aws-quicksight";

// Personality Evolution
export const evolutionGraph2 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/37cc6cda-5ea8-410b-b63c-ec040992c460/sheets/37cc6cda-5ea8-410b-b63c-ec040992c460_247fbd73-7045-461d-a422-389fccb49ca3/visuals/37cc6cda-5ea8-410b-b63c-ec040992c460_87ad34f3-12ae-45ab-9041-608e9b912891?directory_alias=neom-aws-quicksight";

// Personal skills
export const evolutionGraph3 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/37cc6cda-5ea8-410b-b63c-ec040992c460/sheets/37cc6cda-5ea8-410b-b63c-ec040992c460_247fbd73-7045-461d-a422-389fccb49ca3/visuals/37cc6cda-5ea8-410b-b63c-ec040992c460_7ef88d67-936b-4783-b4bc-f8fcab025096?directory_alias=neom-aws-quicksight";

// Mental Well-being 
export const evolutionGraph4 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/37cc6cda-5ea8-410b-b63c-ec040992c460/sheets/37cc6cda-5ea8-410b-b63c-ec040992c460_247fbd73-7045-461d-a422-389fccb49ca3/visuals/37cc6cda-5ea8-410b-b63c-ec040992c460_fe32609d-1e06-4aa9-bedf-2d4a1e3d5ead?directory_alias=neom-aws-quicksight";


// Engagement Graphs-
// Area Wise Engagement - All
export const engagementGraph1 =
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_d49a94f8-c2e2-4f4a-ac33-efa7b7773a24?directory_alias=neom-aws-quicksight";

 // Material Wise Engagement -All
export const engagementGraph2 =
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_1c162e82-6d35-4b90-963b-b36ce8645fb8?directory_alias=neom-aws-quicksight";

// Area-Wise Engagement -Age Group
export const engagementGraph3=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_a4b27234-1f21-40e0-a1da-feeddc1bc8f7?directory_alias=neom-aws-quicksight";

 // Area-Wise Engagement - Gender
export const engagementGraph4=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_1025ebe4-b3da-421c-bccf-ab45bea4e06f?directory_alias=neom-aws-quicksight";

// Daylight Engagement - Time
export const engagementGraph5=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_7b68aae0-312d-4f65-8f41-24d704fed32d?directory_alias=neom-aws-quicksight";

// Sound Engagement - Area By Age Group
export const engagementGraph6=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_8fc9ef18-ad3d-4b8e-b2c7-92752bf6c082?directory_alias=neom-aws-quicksight";

// Temprature Engagement -
export const engagementGraph7=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_391a6985-eead-403a-8afb-de092b260a28?directory_alias=neom-aws-quicksight";

// Wind Engagement -
export const engagementGraph8=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_9066f6a8-18c3-4824-b050-c7ad6f37bdef?directory_alias=neom-aws-quicksight";

// Material Engagement - Area
export const engagementGraph9=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_8d90a33d-2b5d-43e3-b1fd-f086cdcc194c?directory_alias=neom-aws-quicksight";

// Material Engagement - Age Group
export const engagementGraph10=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_ab0d1e27-4383-43b9-ac25-153a51b03462?directory_alias=neom-aws-quicksight";

// Material Engagement -Gender
export const engagementGraph11=
"https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/6381b638-733b-4b37-beee-708a89b67790/sheets/6381b638-733b-4b37-beee-708a89b67790_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/6381b638-733b-4b37-beee-708a89b67790_3714a514-b102-463c-95c7-6425d4a30538?directory_alias=neom-aws-quicksight";


export const categoryOptions = [
  { value: "Evolution", label: "Evolution" },
  { value: "Engagement", label: "Engagement" },
];

export const module45Data = [
  { name: "Module 45 Reports", date: "3 Jan, 25", status: "Done" },
];
export const tiranData = [
  { name: "Tiran Reports", date: "3 Jan, 25", status: "Done" },
];

export const graphs = {
  evolution: [
    { url: evolutionGraph1, filtered: false, modules: ["Module45", "Tiran"] },
    { url: evolutionGraph4, filtered: false, modules: ["Module45", "Tiran"] },
    { url: evolutionGraph2, filtered: false, modules: ["Module45", "Tiran"] },
    { url: evolutionGraph3, filtered: false, modules: ["Module45", "Tiran"] },
  ],
  engagement: [
    { url: engagementGraph1, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph2, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph3, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph4, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph5, filtered: true, modules: ["Module45"] },
    { url: engagementGraph6, filtered: false, modules: ["Module45"] },
    { url: engagementGraph7, filtered: true, modules: ["Tiran"] },
    { url: engagementGraph8, filtered: false, modules: ["Tiran"] },
    { url: engagementGraph9, filtered: true, modules: ["Module45", "Tiran"] },
    { url: engagementGraph10, filtered: true, modules: ["Module45", "Tiran"] },
    { url: engagementGraph11, filtered: true, modules: ["Module45", "Tiran"] },
  ],
};

export const m45PersonalSkillsOptions = [
  { value: "Walkable Street", label: "Walkable Street" },
  { value: "Stadium", label: "Stadium" },
  { value: "Hospital", label: "Hospital" },
  { value: "Park", label: "Park" },
  { value: "NEOMU", label: "NEOMU" },
  { value: "Library", label: "Library" },
];
export const tiranPersonalSkillsOptions = [
  { value: "Waterfront", label: "Waterfront" },
  { value: "Miracle Garden", label: "Miracle Garden" },
  { value: "Academia", label: "Academia" },
  { value: "Wonderdome", label: "Wonderdome" },
];
export const allField = { value: "All", label: "All" };
